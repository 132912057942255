<template>
  <nav class="ok-lang">
    <button
      v-for="l in langs"
      :key="l.value"
      type="button"
      :class="$i18n.locale === l.value ? 'active' : ''"
      @click="setLocale(l.value)"
    >
      {{ l.label }}
    </button>
  </nav>
</template>

<script>
export default {
  name: 'ok-lang',
  data: () => ({
    langs: [
      { label: 'En', value: 'en' },
      { label: 'Ru', value: 'ru' },
      { label: 'Ua', value: 'uk' }
    ]
  }),
  methods: {
    setLocale (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss">
.ok-lang {
  display: inline-block;
  margin-bottom: 1rem;
  text-align: center;
  button {
    background-color: transparent;
    padding: 12px;
    color: white;
    font-size: 18px;
    border: 0;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    &.active {
      background-color: #fff;
      color: #000;
    }
  }
}
</style>
